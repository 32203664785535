import { ApolloClient } from "apollo-client";
import { HttpLink } from "apollo-link-http";
import { InMemoryCache } from "apollo-cache-inmemory";
import { server } from "./constants/server";

const client = new ApolloClient({
	link: new HttpLink({
		uri: server + "/graphql",
	}),
	cache: new InMemoryCache(),
});

export default client;
