import HostDetail from "../containers/hostDetail";
import { graphql } from "react-apollo";
import gql from "graphql-tag";

const Query = gql`
	query getEvent($event: ID!) {
		event(id: $event) {
			_id
			title
			start
			end
			roomcode
			language {
				_id
				short
			}
			games {
				_id
				title
				description
				background {
					url
				}
				gametype {
					_id
					slug
				}
				topic {
					_id
					title
				}
			}
		}
	}
`;

const QueryParams = {
	options: props => ({
		variables: { event: props.match.params.eventId },
	}),
};

export default graphql(Query, QueryParams)(HostDetail);
