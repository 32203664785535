import React from "react";
import { Redirect, withRouter } from "react-router-dom";
import { useRoute } from "../../hooks/use-route";

export const RedirectStart = withRouter(({ location: { pathname }, path }) => {
	if (pathname) {
		return (
			<Redirect
				to={{
					pathname: useRoute("code"),
					state: { from: path },
				}}
			/>
		);
	} else {
		return null;
	}
});
