import React from "react";
import {
	BrowserRouter as Router,
	Route,
	Switch,
	Redirect,
} from "react-router-dom";
import LangRouter from "../screens/langRouter";
import { getLang, getRoute } from "../helpers/language";
import Loading from "../components/loading";
import Page from "../components/page";
import Error from "../components/error";
import routes from "../constants/routes";
import Device from "../screens/device";

export default ({ data: { loading, error, languages, medias } }) => {
	if (loading) {
		return (
			<Page title="Loading">
				<Loading />
			</Page>
		);
	} else if (error) {
		return (
			<Page title="Error">
				<Error error={error} />
			</Page>
		);
	} else if (!loading && !error && languages && medias) {
		const currentLang = getLang(languages);
		return (
			<Router>
				<Switch>
					<Route
						path={`/:lng(${getRoute(languages)})`}
						component={props => (
							<LangRouter {...props} languages={languages} medias={medias} />
						)}
					/>
					<Route exact path={`${routes.device}/:deviceId`} component={Device} />
					{currentLang && <Redirect from={`/`} to={`/${currentLang}`} />}
				</Switch>
			</Router>
		);
	}
};
