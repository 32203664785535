import React from "react";
import styled from "styled-components";
import QRCode from "qrcode.react";

const StyledQRCode = styled(QRCode).attrs({
	size: 100,
	renderAs: "svg",
})`
	box-sizing: content-box;
	background: ${({ theme }) => theme.color.white};
	margin: 0;
`;

export default ({ url }) => <StyledQRCode value={url} />;
