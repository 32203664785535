import Game from "../containers/game";
import { graphql, compose } from "react-apollo";
import gql from "graphql-tag";

const Query = gql`
	query gameAndEvent($game: ID!, $event: ID!) {
		event(id: $event) {
			_id
			title
			start
			end
			roomcode
		}
		game(id: $game) {
			_id
			title
			description
			background {
				_id
				url
			}
			gametype {
				_id
				slug
			}
			topic {
				_id
				title
				questiongroups(sort: "order:desc") {
					_id
					title
					questions {
						_id
						question
						answer_1
						answer_2
						answer_3
						answer_4
						answer_1_bool
						answer_2_bool
						answer_3_bool
						answer_4_bool
						explanation
					}
				}
			}
			language {
				_id
				title
			}
		}
	}
`;

const QueryParams = {
	options: props => ({
		variables: {
			game: props.match.params.gameId,
			event: props.match.params.eventId,
		},
	}),
};

const createPlayer = gql`
	mutation createPlayer($name: String, $code: String, $game: ID, $event: ID) {
		createPlayer(
			input: { data: { name: $name, code: $code, game: $game, event: $event } }
		) {
			player {
				_id
				name
			}
		}
	}
`;

const updatePlayer = gql`
	mutation updatePlayer(
		$_id: ID!
		$firstname: String
		$lastname: String
		$email: String
		$street: String
		$number: String
		$zip: String
		$city: String
	) {
		updatePlayer(
			input: {
				where: { id: $_id }
				data: {
					firstname: $firstname
					lastname: $lastname
					email: $email
					street: $street
					number: $number
					zip: $zip
					city: $city
				}
			}
		) {
			player {
				_id
			}
		}
	}
`;

export default compose(
	graphql(Query, QueryParams),
	graphql(createPlayer, { name: "createPlayerMutation" }),
	graphql(updatePlayer, { name: "updatePlayerMutation" })
)(Game);
