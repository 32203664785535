import React, { useState } from "react";
import styled from "styled-components";

const Form = styled.form`
	display: flex;
	flex-direction: column;
	box-sizing: border-box;
	width: 100%;
	margin: 0 auto;
`;

const Row = styled.div`
	display: flex;
	flex-direction: row;
	width: 100%;
	box-sizing: border-box;
`;

const Submit = styled.input.attrs({ type: "submit" })`
	flex: 1;
	outline: none;
	margin: 0;
	height: 3em;
	font-size: ${({ theme }) => theme.fontSize.regular};
	padding: ${({ theme }) => theme.spacing.regular};
	justify-content: center;
	box-sizing: border-box;
	background: ${({ theme }) => theme.color.green};
	color: ${({ theme }) => theme.color.white};
	border: none;
	width: 100%;
	max-width: 100%;
	display: flex;
	cursor: pointer;
`;

const Input = styled.input`
	flex: 1;
	outline: none;
	margin: 0;
	height: 3em;
	font-size: ${({ theme }) => theme.fontSize.regular};
	padding: ${({ theme }) => theme.spacing.regular};
	box-sizing: border-box;
	background: ${({ theme }) => theme.color.white};
	color: ${({ theme }) => theme.color.black};
	border: none;
	width: 100%;
	max-width: 100%;
	display: flex;
	transition: all 0.3s ease-in-out;
	&:invalid {
		background: ${({ error, theme }) => (error ? theme.color.redLight : null)};
		color: ${({ theme, error }) => (error ? theme.color.white : null)};
	}
`;

const Radio = styled.div`
	flex: 1;
	outline: none;
	margin: 0;
	min-height: 3em;
	font-size: ${({ theme }) => theme.fontSize.regular};
	padding: ${({ theme }) => theme.spacing.regular};
	box-sizing: border-box;
	background: ${({ theme, active }) =>
		active ? theme.color.green : theme.color.white};
	color: ${({ theme, active }) =>
		active ? theme.color.white : theme.color.black};
	border: none;
	width: 100%;
	max-width: 100%;
	display: flex;
	align-items: center;
	transition: all 0.3s ease-in-out;
	cursor: pointer;
`;

export default ({
	fields,
	getChange = () => {},
	submit: { title, action },
}) => {
	const [formData, setFormData] = useState({});
	const [showError, setShowError] = useState(false);
	const onChange = (name, value) => {
		getChange();
		if (value === "" || !value) {
			clearInput(name);
		} else {
			let obj = {};
			obj[name] = value;
			setFormData({ ...formData, ...obj });
		}
	};
	const clearInput = name => {
		const obj = Object.keys(formData).reduce((object, key) => {
			if (key !== name) {
				object[key] = formData[key];
			}
			return object;
		}, {});
		setFormData(obj);
	};
	const getValue = name => formData[name] || "";
	return (
		<Form
			autoComplete="off"
			noValidate
			onSubmit={e => {
				e.preventDefault();
				if (!e.target.checkValidity()) {
					setShowError(true);
				} else {
					setShowError(false);
					action(formData);
				}
			}}
		>
			{fields.map((field, index) => (
				<Row key={index}>
					{field.type === "radio" ? (
						<Radio
							children={field.title}
							onClick={() => {
								onChange(
									field.name,
									formData[field.name] === field.value ? null : field.value
								);
							}}
							active={formData[field.name] === field.value}
						/>
					) : (
						<Input
							error={showError}
							name={field.name}
							type={field.type}
							required={field.required}
							placeholder={field.placeholder}
							autoComplete={field.autocomplete}
							onChange={e => onChange(field.name, e.target.value)}
							value={getValue(field.name)}
						/>
					)}
				</Row>
			))}
			<Row>
				<Submit value={title} />
			</Row>
		</Form>
	);
};
