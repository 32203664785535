import React, { useEffect, useState } from "react";
import { useTranslate } from "../hooks/use-translate";
import Page from "../components/page";
import getImage from "../helpers/get-image";
import socket from "../ws";
import styled from "styled-components";
import Form from "../components/form";
import { Link } from "react-router-dom";
import { useRoute } from "../hooks/use-route";
import { mautic } from "../constants/server";

const InitScreenWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  flex: 1;
  justify-content: center;
  align-items: center;
  overflow: auto;
`;

const InitScreenForm = styled.div`
  max-height: 100%;
  background: ${({ theme }) => theme.color.white};
  width: 100%;
  max-width: 350px;
  box-shadow: ${({ theme }) => theme.shadow.base};
  display: flex;
  flex-direction: column;
`;

const Message = styled.div`
  flex: 1;
  outline: none;
  margin: 0;
  min-height: 3em;
  font-size: ${({ theme }) => theme.fontSize.regular};
  margin: ${({ theme }) => theme.spacing.regular};
  justify-content: center;
  box-sizing: border-box;
  background: ${({ theme }) => theme.color.white};
  color: ${({ theme }) => theme.color.black};
  border: none;
  width: ${({ theme }) => `calc(100% - 2 * ${theme.spacing.regular})`};
  max-width: ${({ theme }) => `calc(100% - 2 * ${theme.spacing.regular})`};
  display: flex;
`;

const Button = styled(Link)`
  flex: 1;
  outline: none;
  margin: 0;
  min-height: 3em;
  font-size: ${({ theme }) => theme.fontSize.regular};
  padding: ${({ theme }) => theme.spacing.regular};
  justify-content: center;
  box-sizing: border-box;
  background: ${({ theme }) => theme.color.green};
  color: ${({ theme }) => theme.color.white};
  border: none;
  width: 100%;
  max-width: 100%;
  display: flex;
  cursor: pointer;
  text-decoration: none;
`;

const HostNotFound = () => (
  <InitScreenWrapper>
    <InitScreenForm>
      <Message>{useTranslate("hostNotFound")}</Message>
      <Button to={useRoute("code")}>{useTranslate("goBack")}</Button>
    </InitScreenForm>
  </InitScreenWrapper>
);

const HostDisconnected = () => (
  <InitScreenWrapper>
    <InitScreenForm>
      <Message>{useTranslate("hostDisconnected")}</Message>
      <Button to={useRoute("code")}>{useTranslate("goBack")}</Button>
    </InitScreenForm>
  </InitScreenWrapper>
);

const HostLocked = () => (
  <InitScreenWrapper>
    <InitScreenForm>
      <Message>{useTranslate("hostLocked")}</Message>
      <Button to={useRoute("code")}>{useTranslate("goBack")}</Button>
    </InitScreenForm>
  </InitScreenWrapper>
);

const InitScreen = ({ setName = () => {} }) => (
  <InitScreenWrapper>
    <InitScreenForm>
      <Form
        fields={[
          {
            placeholder: useTranslate("name"),
            type: "text",
            autocomplete: "name",
            name: "name",
            required: true
          }
        ]}
        submit={{
          title: useTranslate("next"),
          action: ({ name }) => setName(name)
        }}
      />
    </InitScreenForm>
  </InitScreenWrapper>
);

const ChooseScreenWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const ChooseScreenForm = styled.div`
  background: ${({ theme }) => theme.color.white};
  width: 100%;
  max-width: 350px;
  box-shadow: ${({ theme }) => theme.shadow.base};
  overflow-y: auto;
  z-index: 10;
`;

const ChooseError = styled.div`
  flex: 1;
  outline: none;
  margin: 0;
  max-height: ${({ open }) => (open ? `8em` : `0px`)};
  overflow: hidden;
  font-size: ${({ theme }) => theme.fontSize.regular};
  padding: ${({ theme, open }) =>
    open ? theme.spacing.regular : `0 ${theme.spacing.regular}`};
  box-sizing: border-box;
  background: ${({ theme }) => theme.color.redLight};
  color: ${({ theme }) => theme.color.black};
  border: none;
  width: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
  transition: max-height 0.3s ease-in-out, padding 0.3s ease-in-out;
`;

const ChooseScreen = ({ questiongroups = [], setQuestionGroup = () => {} }) => {
  const [showTopicError, setShowTopicError] = useState(false);
  const setTopic = topic => {
    if (topic) {
      setQuestionGroup(topic);
    } else {
      setShowTopicError(true);
    }
  };
  return (
    <ChooseScreenWrapper>
      <ChooseScreenForm>
        <ChooseError open={showTopicError}>
          {useTranslate("chooseOneTopic")}
        </ChooseError>
        <Form
          getChange={() => setShowTopicError(false)}
          fields={questiongroups.map(item => ({
            type: "radio",
            name: "topic",
            value: item._id,
            title: item.title
          }))}
          submit={{
            title: useTranslate("next"),
            action: ({ topic }) => setTopic(topic)
          }}
        />
      </ChooseScreenForm>
    </ChooseScreenWrapper>
  );
};

const WaitForPlayersWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  flex: 1;
  justify-content: center;
  align-items: center;
`;

const WaitForPlayersForm = styled.div`
  background: ${({ theme }) => theme.color.white};
  width: 100%;
  max-width: 350px;
  box-shadow: ${({ theme }) => theme.shadow.base};
`;

const WaitForPlayersText = styled.div`
  flex: 1;
  outline: none;
  margin: 0;
  overflow: hidden;
  min-height: 3em;
  font-size: ${({ theme }) => theme.fontSize.regular};
  padding: ${({ theme }) => theme.spacing.regular};
  box-sizing: border-box;
  background: ${({ theme }) => theme.color.white};
  color: ${({ theme }) => theme.color.black};
  border: none;
  width: 100%;
  max-width: 100%;
  display: flex;
  align-items: center;
`;

const WaitForPlayersButton = styled(WaitForPlayersText)`
  background: ${({ theme, isSub }) =>
    isSub ? theme.color.white : theme.color.green};
  color: ${({ theme, isSub }) =>
    isSub ? theme.color.black : theme.color.white};
`;

const WaitForPlayers = ({ setAllPlayers = () => {} }) => (
  <WaitForPlayersWrapper>
    <WaitForPlayersForm>
      <WaitForPlayersText>{useTranslate("allPlayersReady")}</WaitForPlayersText>
      <WaitForPlayersButton onClick={setAllPlayers}>
        {useTranslate("yes")}
      </WaitForPlayersButton>
    </WaitForPlayersForm>
  </WaitForPlayersWrapper>
);

const WaitForTopics = () => (
  <WaitForPlayersWrapper>
    <WaitForPlayersForm>
      <WaitForPlayersText>{useTranslate("waitForTopics")}</WaitForPlayersText>
    </WaitForPlayersForm>
  </WaitForPlayersWrapper>
);

const QuestionContentWrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  width: 100%;
  height: 100%;
  max-width: 90%;
  max-height: 90%;
  margin: auto;
`;

const QuestionContentGrid = styled.div`
  flex: 1;
  display: grid;
  grid-template-columns: 50% 50%;
  margin: 0.25em;
  box-shadow: ${({ theme }) => theme.shadow.base};
  padding: 0.25em;
  background: ${({ theme }) => theme.color.white};
`;

const QuestionContentItem = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: calc(0.25em / 2);
  font-size: 2em;
  cursor: pointer;
  background: ${({ theme, index }) =>
    index === 1
      ? theme.color.yellow
      : index === 2
      ? theme.color.red
      : index === 3
      ? theme.color.purple
      : index === 4
      ? theme.color.blue
      : null};
`;

const Question = ({ game, topic, question, setAnswer = () => {} }) => (
  <QuestionContentWrapper>
    {topic !== null && question !== null && (
      <QuestionContentGrid>
        {game.topic.questiongroups[topic].questions[question].answer_1 !==
          "" && (
          <QuestionContentItem
            index={1}
            onClick={() => setAnswer(topic, question, 1)}
          >
            {useTranslate("answera")}
          </QuestionContentItem>
        )}
        {game.topic.questiongroups[topic].questions[question].answer_2 !==
          "" && (
          <QuestionContentItem
            index={2}
            onClick={() => setAnswer(topic, question, 2)}
          >
            {useTranslate("answerb")}
          </QuestionContentItem>
        )}
        {game.topic.questiongroups[topic].questions[question].answer_3 !==
          "" && (
          <QuestionContentItem
            index={3}
            onClick={() => setAnswer(topic, question, 3)}
          >
            {useTranslate("answerc")}
          </QuestionContentItem>
        )}
        {game.topic.questiongroups[topic].questions[question].answer_4 !==
          "" && (
          <QuestionContentItem
            index={4}
            onClick={() => setAnswer(topic, question, 4)}
          >
            {useTranslate("answerd")}
          </QuestionContentItem>
        )}
      </QuestionContentGrid>
    )}
  </QuestionContentWrapper>
);

const WaitForAnswers = () => (
  <WaitForPlayersWrapper>
    <WaitForPlayersForm>
      <WaitForPlayersText>{useTranslate("waitForAnswers")}</WaitForPlayersText>
    </WaitForPlayersForm>
  </WaitForPlayersWrapper>
);

const ShowResult = ({ showResult = () => {} }) => (
  <WaitForPlayersWrapper>
    <WaitForPlayersForm>
      <WaitForPlayersText>{useTranslate("showResult")}</WaitForPlayersText>
      <WaitForPlayersButton onClick={showResult}>
        {useTranslate("yes")}
      </WaitForPlayersButton>
    </WaitForPlayersForm>
  </WaitForPlayersWrapper>
);

const Answer = ({ goNextQuestion = () => {} }) => (
  <WaitForPlayersWrapper>
    <WaitForPlayersForm>
      <WaitForPlayersText>
        {useTranslate("goToNextQuestion")}
      </WaitForPlayersText>
      <WaitForPlayersButton onClick={goNextQuestion}>
        {useTranslate("yes")}
      </WaitForPlayersButton>
    </WaitForPlayersForm>
  </WaitForPlayersWrapper>
);

const goToCode = () => {
  if (window.location.port) {
    window.location =
      window.location.protocol +
      "//" +
      window.location.hostname +
      ":" +
      window.location.port;
  } else {
    window.location =
      window.location.protocol + "//" + window.location.hostname;
  }
};

const Result = ({ goNewsletter = () => {} }) => (
  <WaitForPlayersWrapper>
    <WaitForPlayersForm>
      <WaitForPlayersText>{useTranslate("goToNewsletter")}</WaitForPlayersText>
      <WaitForPlayersButton onClick={goNewsletter}>
        {useTranslate("yes")}
      </WaitForPlayersButton>
      <WaitForPlayersButton isSub onClick={goToCode}>
        {useTranslate("later")}
      </WaitForPlayersButton>
    </WaitForPlayersForm>
  </WaitForPlayersWrapper>
);

const Newsletter = ({ submitNewsletter = () => {} }) => {
  return (
    <InitScreenWrapper>
      <InitScreenForm>
        <Form
          fields={[
            {
              placeholder: useTranslate("firstname"),
              type: "text",
              autocomplete: "firstname",
              name: "firstname",
              required: true
            },
            {
              placeholder: useTranslate("lastname"),
              type: "text",
              autocomplete: "lastname",
              name: "lastname",
              required: true
            },
            {
              placeholder: useTranslate("email"),
              type: "email",
              autocomplete: "email",
              name: "email",
              required: true
            },
            {
              placeholder: useTranslate("street"),
              type: "text",
              autocomplete: "street",
              name: "street",
              required: true
            },
            {
              placeholder: useTranslate("housenumber"),
              type: "text",
              autocomplete: "number",
              name: "number",
              required: true
            },
            {
              placeholder: useTranslate("zip"),
              type: "text",
              autocomplete: "zip",
              name: "zip",
              required: true
            },
            {
              placeholder: useTranslate("city"),
              type: "text",
              autocomplete: "city",
              name: "city",
              required: true
            }
          ]}
          submit={{
            title: useTranslate("send"),
            action: submitNewsletter
          }}
        />
      </InitScreenForm>
    </InitScreenWrapper>
  );
};

const Thanks = () => (
  <InitScreenWrapper>
    <InitScreenForm>
      <Message>{useTranslate("thanks")}</Message>
      <Button to={useRoute("code")}>{useTranslate("goBack")}</Button>
    </InitScreenForm>
  </InitScreenWrapper>
);

export default ({
  match: {
    params: { gameId, eventId, code }
  },
  data: { loading, game, event, error },
  createPlayerMutation,
  updatePlayerMutation
}) => {
  const [hostNotFound, setHostNotFound] = useState(false);
  const [hostDisconnected, setHostDisconnected] = useState(false);
  const [hostLocked, setHostLocked] = useState(false);
  const [questionState, setQuestionState] = useState({
    topic: null,
    question: null
  });
  const [client, setClient] = useState({
    gameId,
    eventId,
    code,
    setup: false,
    name: ""
  });
  const [screen, setScreen] = useState("init");
  const [playerId, setPlayerId] = useState(null);
  let localFormLocked = false;
  const initSocket = () => {
    socket.emit("clientAdd", JSON.stringify(client));
    socket.on("hostAction", msg => {
      const res = JSON.parse(msg);
      if (res.screen) {
        if (!localFormLocked) {
          setScreen(res.screen);
          if (res.screen === "question" && res.questionState) {
            console.log(res.questionState);
            setQuestionState({ ...res.questionState });
          }
        }
      }
    });
    socket.on("hostLocked", () => setHostLocked(true));
    socket.on("hostNotFound", () => setHostNotFound(true));
    socket.on("disconnect", () => setHostDisconnected(true));
    socket.on("hostDisconnect", () => setHostDisconnected(true));
  };
  const setName = name => {
    setClient({ ...client, name });
    createPlayerMutation({
      variables: { name, game: gameId, event: eventId, code }
    }).then(res => {
      setPlayerId(res.data.createPlayer.player._id);
    });
    socket.emit(
      "clientUpdate",
      JSON.stringify({ ...client, name, setup: true })
    );
    setScreen("waitforplayers");
  };

  const setAllPlayers = () => {
    socket.emit("clientLockHost");
    setScreen("choosetopic");
  };

  const setQuestionGroup = topic => {
    socket.emit("clientAction", JSON.stringify({ topic }));
    setScreen("waitfortopics");
  };

  const setAnswer = (topic, question, answer) => {
    socket.emit(
      "clientAction",
      JSON.stringify({ answer: { topic, question, answer } })
    );
    setScreen("waitforanswers");
  };

  const goNextQuestion = () => {
    socket.emit("clientAction", JSON.stringify({ nextquestion: true }));
  };

  const showResult = () => {
    socket.emit("clientAction", JSON.stringify({ showresult: true }));
  };

  const goNewsletter = () => {
    localFormLocked = true;
    setScreen("newsletter");
    socket.emit("disconnect");
    socket.close();
  };

  const generateFormData = obj => {
    let res = "";
    Object.keys(obj).forEach((key, index) => {
      const val = Object.values(obj)[index];
      res += `mauticform[${key}]=${val}&`;
    });
    return res;
  };

  const submitNewsletter = form => {
    fetch(mautic + "/form/submit", {
      method: "POST",
      mode: "no-cors",
      headers: {
        "Content-Type": "application/x-www-form-urlencoded"
      },
      body: generateFormData({
        formId: 19,
        ...form
      })
    }).then(() => {
      setScreen("thanks");
    });
    updatePlayerMutation({
      variables: { ...form, _id: playerId, game: gameId, event: eventId }
    });
  };

  useEffect(() => {
    socket.open();
    initSocket();
    return () => {
      socket.close();
    };
  }, []);
  return (
    <Page
      title={game && game.title}
      image={getImage(game)}
      loading={loading}
      error={error}
    >
      {hostNotFound && <HostNotFound />}
      {hostDisconnected && <HostDisconnected />}
      {hostLocked && <HostLocked />}
      {!hostNotFound &&
        !hostDisconnected &&
        !hostLocked &&
        screen === "init" && <InitScreen setName={setName} />}
      {!hostNotFound &&
        !hostDisconnected &&
        !hostLocked &&
        screen === "waitforplayers" && (
          <WaitForPlayers setAllPlayers={setAllPlayers} />
        )}
      {game &&
        !hostNotFound &&
        !hostDisconnected &&
        !hostLocked &&
        screen === "choosetopic" && (
          <ChooseScreen
            questiongroups={game.topic.questiongroups}
            setQuestionGroup={setQuestionGroup}
          />
        )}
      {game &&
        !hostNotFound &&
        !hostDisconnected &&
        !hostLocked &&
        screen === "waitfortopics" && <WaitForTopics />}
      {game &&
        !hostNotFound &&
        !hostDisconnected &&
        !hostLocked &&
        screen === "question" && (
          <Question {...questionState} game={game} setAnswer={setAnswer} />
        )}
      {game &&
        !hostNotFound &&
        !hostDisconnected &&
        !hostLocked &&
        screen === "waitforanswers" && <WaitForAnswers />}
      {game &&
        !hostNotFound &&
        !hostDisconnected &&
        !hostLocked &&
        screen === "answer" && <Answer goNextQuestion={goNextQuestion} />}
      {game &&
        !hostNotFound &&
        !hostDisconnected &&
        !hostLocked &&
        screen === "showresult" && <ShowResult showResult={showResult} />}
      {game &&
        !hostNotFound &&
        !hostDisconnected &&
        !hostLocked &&
        screen === "result" && <Result goNewsletter={goNewsletter} />}
      {screen === "newsletter" && (
        <Newsletter submitNewsletter={submitNewsletter} />
      )}
      {screen === "thanks" && <Thanks />}
    </Page>
  );
};
