import React, { createContext } from "react";
import { Switch, Route } from "react-router-dom";
import Page from "../components/page";
import Error from "../components/error";
import Loading from "../components/loading";
import NavigationPage from "../components/navigation-page";
import { RedirectStart } from "../components/router";

import { setLang } from "../helpers/language";
import routes from "../constants/routes";

import Code from "../screens/code";
import Host from "../screens/host";
import HostDetail from "../screens/hostDetail";
import HostGame from "../screens/hostGame";
import Game from "../screens/game";

export const LanguageContext = createContext(null);
export const RouteContext = createContext(null);
export const MediaContext = createContext(null);

export default ({
	data: { loading, language, error },
	history,
	location,
	match,
	languages,
	medias,
}) => {
	const media = medias && medias.length > 0 ? medias[0] : {};
	const lang = language && language.length === 1 ? language[0] : {};
	const restructTranslations = translation => {
		let res = {};
		translation.forEach(t => {
			res[t.translationkey.key] = t.text;
		});
		return res;
	};
	const translate = key => {
		return (
			restructTranslations(lang.translation)[key] || `{{${key}_${lang.short}}}`
		);
	};
	const updateLang = lang => {
		history.replace(location.pathname.replace(match.url, `/${lang}`));
	};
	if (loading) {
		return (
			<Page title="Loading">
				<Loading />
			</Page>
		);
	}
	if (error) {
		return (
			<Page title="Error">
				<Error error={error} />
			</Page>
		);
	}
	if (lang) {
		setLang(lang.short);
	}
	return (
		<MediaContext.Provider value={media}>
			<LanguageContext.Provider
				value={{
					language: {
						...lang,
						translations: restructTranslations(lang.translation),
					},
					languages,
					translate,
					updateLang,
				}}
			>
				<RouteContext.Provider value={routes}>
					<NavigationPage>
						<Switch>
							<Route
								exact
								path={`/${lang.short}${routes.code}`}
								component={Code}
							/>
							<Route
								exact
								path={`/${lang.short}${routes.host}`}
								component={props => <Host lng={lang._id} {...props} />}
							/>
							<Route
								exact
								path={`/${lang.short}${routes.host}/:eventId`}
								component={HostDetail}
							/>
							<Route
								exact
								path={`/${lang.short}${routes.host}/:eventId/:gameId`}
								component={HostGame}
							/>
							<Route
								exact
								path={`/${lang.short}${routes.host}/:eventId/:gameId/:persist`}
								component={HostGame}
							/>
							<Route
								exact
								path={`/${lang.short}${routes.game}/:eventId/:gameId/:code`}
								component={Game}
							/>
							<RedirectStart />
						</Switch>
					</NavigationPage>
				</RouteContext.Provider>
			</LanguageContext.Provider>
		</MediaContext.Provider>
	);
};
