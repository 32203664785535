import React from "react";
import Page from "../page";
import Loading from "../loading";
import Error from "../error";
import styled from "styled-components";
import Menu from "../menu";
import { withRouter } from "react-router-dom";
import { useRoute } from "../../hooks/use-route";

const ContentWrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
`;

const Content = ({ loading, error, children }) => {
	if (loading) {
		return <Loading />;
	} else if (error) {
		return <Error error={error} />;
	} else if (!loading && !error && children) {
		return <ContentWrapper>{children}</ContentWrapper>;
	}
};

export default withRouter(
	({
		location: { pathname },
		title,
		description,
		children,
		error,
		loading,
	}) => {
		return (
			<Page title={title} description={description}>
				<Menu
					language={
						pathname === useRoute("code") ||
						pathname === useRoute("host") ||
						pathname === `${useRoute("code")}/` ||
						pathname === `${useRoute("host")}/`
					}
					host={
						pathname === useRoute("code") || pathname === `${useRoute("code")}/`
					}
				/>
				<Content loading={loading} error={error}>
					{children}
				</Content>
			</Page>
		);
	}
);
