import { useContext } from "react";
import { LanguageContext } from "../containers/langRouter";

export const useLanguage = () => {
	const {
		languages,
		updateLang,
		language: { short, ltr, _id },
	} = useContext(LanguageContext) || {
		updateLang: () => {},
		language: { short: "", ltr: "", _id: "" },
		languages: [],
	};
	return { short, dir: ltr ? "LTR" : "RTL", _id, updateLang, languages };
};
