import { useContext } from "react";
import { MediaContext } from "../containers/langRouter";
import { server } from "../constants/server";

export const useMedia = key => {
	const { logo, logo_invert, background } = useContext(MediaContext) || {
		logo: {},
		logo_invert: {},
		background: {},
	};
	return {
		logo: `${server}${logo.url}`,
		invert: `${server}${logo_invert.url}`,
		background: `${server}${background.url}`,
	};
};
