import { useRoute } from "../hooks/use-route";

export const gameLink = (eventId, gameId, room) => {
	const protocol = window.location.protocol;
	const hostname = window.location.hostname;
	const port = window.location.port;
	let prefix;
	if (port) {
		prefix = protocol + "//" + hostname + ":" + port;
	} else {
		prefix = protocol + "//" + hostname;
	}
	return `${prefix}${useRoute("game")}/${eventId}/${gameId}/${room}`;
};
