import storage from "./storage";
import detectBrowserLanguage from "detect-browser-language";

const browserLanguage = () => {
	let language = detectBrowserLanguage();
	if (language.includes("-")) {
		return language.substr(0, language.indexOf("-")).toLowerCase();
	} else {
		return language.toLowerCase();
	}
};

export const getLang = languages => {
	const storageLang = storage.get("language");
	if (
		languages &&
		languages.length &&
		!languages.some(l => l.short === storageLang) &&
		!storageLang
	) {
		if (languages.filter(l => l.short === browserLanguage()).length === 1) {
			return browserLanguage();
		} else {
			if (languages.filter(language => language.default).length === 1) {
				return languages.filter(l => l.default)[0].short;
			} else {
				return languages[0].short;
			}
		}
	} else {
		return storageLang;
	}
};

export const setLang = language => {
	storage.set("language", language);
};

export const getRoute = languages => {
	return languages
		.map(({ short }, index) => {
			if (index < languages.length - 1) {
				return `${short}|`;
			} else {
				return short;
			}
		})
		.join("");
};

export default { getLang, getRoute, setLang };
