import HostGame from "../containers/hostGame";
import { graphql } from "react-apollo";
import gql from "graphql-tag";

const Query = gql`
  query getGame($game: ID!) {
    game(id: $game) {
      _id
      title
      description
      background {
        _id
        url
      }
      gametype {
        _id
        slug
      }
      topic {
        _id
        title
        questiongroups(sort: "order:desc") {
          _id
          title
          questions {
            _id
            question
            answer_1
            answer_2
            answer_3
            answer_4
            answer_1_bool
            answer_2_bool
            answer_3_bool
            answer_4_bool
            explanation
          }
        }
      }
      language {
        _id
        title
        short
      }
    }
  }
`;

const QueryParams = {
  options: props => ({
    variables: { game: props.match.params.gameId }
  })
};

export default graphql(Query, QueryParams)(HostGame);
