import React from "react";
import { useTranslate } from "../hooks/use-translate";
import Page from "../components/page";
import styled from "styled-components";
import Grid from "styled-components-grid";
import { Link } from "react-router-dom";
import { useRoute } from "../hooks/use-route";
import getImage from "../helpers/get-image";

const EventHeader = styled.div`
	width: 100%;
	height: 80px;
	padding: 0 ${({ theme }) => theme.spacing.large};
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	background-color: ${({ theme }) => theme.color.green};
	color: ${({ theme }) => theme.color.white};
	justify-content: center;
`;

const EventTitle = styled.div`
	font-size: ${({ theme }) => theme.fontSize.large};
	padding: ${({ theme }) => `0 ${theme.spacing.regular}`};
`;

const GameGridWrapper = styled.div`
	width: 100%;
	display: flex;
	flex: 1;
	overflow-y: auto;
	padding: ${({ theme }) => theme.spacing.large};
	box-sizing: border-box;
`;

const GameGrid = styled(Grid)`
	width: 100%;
	min-height: 100%;
	max-width: 1400px;
	margin: 0 auto;
`;

const GameGridItemWrapper = styled(Grid.Unit)`
	padding: ${({ theme }) => theme.spacing.large};
	display: flex;
	flex-direction: column;
	max-height: 300px;
`;

const GameGridItemLink = styled(Link)`
	width: 100%;
	height: 100%;
	display: flex;
	flex: 1;
	flex-direction: column;
	text-decoration: unset;
	color: unset;
	box-shadow: ${({ theme }) => theme.shadow.base};
	cursor: pointer;
`;

const GameGridItemContent = styled.div`
	display: flex;
	flex: 1;
	width: 100%;
	flex-direction: column;
	background-image: ${({ image }) => (image ? `url(${image})` : null)};
	background-size: cover;
	background-position: center center;
	background-repeat: no-repeat;
`;

const GameGridItemContentOverlay = styled.div`
	height: 100%;
	width: 100%;
	display: flex;
	flex-direction: column;
	flex: 1;
	background-color: ${({ theme }) => theme.color.opacity};
`;

const GameGridItemTitle = styled.div`
	display: flex;
	flex: 1;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-size: ${({ theme }) => theme.fontSize.large};
	color: ${({ theme }) => theme.color.white};
	padding: 1em 0;
`;

const GameGridItemType = styled.div`
	padding: ${({ theme }) => theme.spacing.small};
	font-size: ${({ theme }) => theme.fontSize.small};
	color: ${({ theme }) => theme.color.black};
	diplay: flex;
	flex-direction: column;
	align-items: flex-start;
	justify-content: center;
`;

export default ({ data: { loading, error, event } }) => {
	return (
		<Page title={event && event.title} loading={loading} error={error}>
			<EventHeader>
				<EventTitle>{event && event.title}</EventTitle>
			</EventHeader>
			<GameGridWrapper>
				<GameGrid>
					{event &&
						event.games &&
						event.games.map((game, index) => (
							<GameGridItemWrapper
								key={index}
								size={{ xs: 1, md: 1 / 2, xl: 1 / 3 }}
							>
								<GameGridItemLink
									to={`${useRoute("host")}/${event._id}/${game._id}`}
								>
									<GameGridItemContent image={getImage(game)}>
										<GameGridItemContentOverlay>
											<GameGridItemTitle>{game.title}</GameGridItemTitle>
										</GameGridItemContentOverlay>
									</GameGridItemContent>
									<GameGridItemType>
										{useTranslate(game.gametype.slug)}
									</GameGridItemType>
								</GameGridItemLink>
							</GameGridItemWrapper>
						))}
				</GameGrid>
			</GameGridWrapper>
		</Page>
	);
};
