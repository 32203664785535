import React from "react";
import Page from "../components/page";
import { Redirect } from "react-router-dom";

export default ({ data: { loading, error, device } }) => {
	return (
		<Page loading={loading}>
			{device && <Redirect to={device.route} />}
			{error && <Redirect to={`/`} />}
		</Page>
	);
};
