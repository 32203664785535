import React, { useState } from "react";
import styled from "styled-components";
import { useLanguage } from "../../hooks/use-language";
import { useMedia } from "../../hooks/use-media";
import { FiGlobe, FiMonitor } from "react-icons/fi";
import ReactCountryFlag from "react-country-flag";
import { getMetaTag } from "../../helpers/meta-tag";
import { useRoute } from "../../hooks/use-route";
import { Link } from "react-router-dom";

const MenuWrapper = styled.div`
	display: flex;
	flex-direction: column;
`;

const MenuBarWrapper = styled.div`
	height: 80px;
	background: ${({ theme }) => theme.color.white};
	display: flex;
	flex-direction: row;
	align-items: center;
	padding: ${({ theme }) => `${theme.spacing.regular}`};
	z-index: 20;
	box-sizing: border-box;
	box-shadow: ${({ theme }) => theme.shadow.base};
`;

const MenuBarLogo = styled.div`
	height: 100%;
	width: 160px;
	background-image: ${({ logo }) => (logo ? `url(${logo})` : null)};
	background-size: contain;
	background-position: ${({ dir }) =>
		dir === "LTR" ? `center left` : `center right`};
	background-repeat: no-repeat;
`;

const MenuBarLanguageWrapper = styled.div`
	display: flex;
	flex: 1;
	flex-direction: row;
	height: 100%;
	justify-content: flex-end;
	align-items: center;
`;

const MenuBarLanguageIcon = styled.div`
	padding: ${({ theme }) => theme.spacing.small};
	height: 1em;
	width: 1em;
	cursor: pointer;
	> svg {
		width: 100%;
		height: 100%;
	}
`;

const MenuBarLanguageIconLink = styled(Link)`
	padding: ${({ theme }) => theme.spacing.small};
	height: 1em;
	width: 1em;
	cursor: pointer;
	text-decoration: none;
	color: unset;
	> svg {
		width: 100%;
		height: 100%;
	}
`;

const MenuBarLanguageContent = styled.div`
	opacity: ${({ active }) => (active ? `1` : `0`)};
	position: absolute;
	top: 80px;
	right: 0;
	bottom: ${({ active }) => (active ? `0px` : `100%`)};
	left: 0;
	transition: all 0.5s ease-in-out;
	display: flex;
	flex-direction: column;
`;

const MenuBarLanguageRow = styled.div`
	height: 100px;
	width: 100%;
	position: relative;
	top: 0px;
	display: flex;
	flex-direction: row;
	overflow-x: auto;
	background-color: ${({ theme }) => theme.color.white};
	transition: top 0.6s ease-in-out;
`;

const MenuBarLanguageSpacer = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	flex: 1;
	overflow: hidden;
	opacity: ${({ active }) => (active ? `1` : `0`)};
	background: ${({ theme }) => theme.color.opacity};
	color: ${({ theme }) => theme.color.white};
	justify-content: flex-end;
	align-items: flex-end;
	padding: ${({ theme }) => theme.spacing.regular};
	box-sizing: border-box;
	transition: all 0.6s ease-in-out;
`;

const CountryFlag = ({ code }) => (
	<ReactCountryFlag
		code={code}
		svg
		styleProps={{
			width: "1.5em",
			height: "1em",
		}}
	/>
);

const LanguageItem = styled.div`
	width: 110px;
	min-width: 110px;
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	font-size: ${({ theme }) => theme.fontSize.small};
	padding: 1em;
	box-sizing: border-box;
	background-color: ${({ active, theme }) =>
		active ? theme.color.green : null};
	cursor: pointer;
	&:hover {
		background-color: ${({ theme }) => theme.color.green};
		color: ${({ theme }) => theme.color.white};
	}
`;

const LanguageName = styled.div`
	margin-top: 0.5em;
	font-size: ${({ theme }) => theme.fontSize.small};
	color: ${({ active, theme }) => (active ? theme.color.white : null)};
`;

export default ({ language, host }) => {
	const [menuOpen, setMenuOpen] = useState(false);
	const toggleMenu = () => setMenuOpen(!menuOpen);
	const { dir, short, updateLang, languages } = useLanguage();
	const { logo } = useMedia();
	return (
		<MenuWrapper>
			<MenuBarWrapper>
				<MenuBarLogo dir={dir} logo={logo} />
				{(language || host) && (
					<MenuBarLanguageWrapper>
						{host && (
							<MenuBarLanguageIconLink to={useRoute("host")}>
								<FiMonitor />
							</MenuBarLanguageIconLink>
						)}
						{language && (
							<MenuBarLanguageIcon onClick={toggleMenu}>
								<FiGlobe />
							</MenuBarLanguageIcon>
						)}
					</MenuBarLanguageWrapper>
				)}
			</MenuBarWrapper>
			<MenuBarLanguageContent active={menuOpen}>
				<MenuBarLanguageRow>
					{languages
						.filter(l => l.short === short)
						.map(({ title, short, flag }, index) => (
							<LanguageItem
								active
								onClick={() => {
									updateLang(short);
									toggleMenu();
								}}
								key={index}
							>
								<CountryFlag code={flag} />
								<LanguageName active>{title}</LanguageName>
							</LanguageItem>
						))}
					{languages
						.filter(l => l.short !== short)
						.map(({ title, short, flag }, index) => (
							<LanguageItem
								onClick={() => {
									updateLang(short);
									toggleMenu();
								}}
								key={index}
							>
								<CountryFlag code={flag} />
								<LanguageName>{title}</LanguageName>
							</LanguageItem>
						))}
				</MenuBarLanguageRow>
				<MenuBarLanguageSpacer active={menuOpen}>
					{`${getMetaTag("branch", "%BRANCH%")} (${getMetaTag(
						"commit",
						"%COMMIT%"
					)}) - ${new Date(
						getMetaTag("builddate", "%BUILDDATE%")
					).toLocaleString("de-DE", {
						year: "numeric",
						month: "long",
						day: "numeric",
						hour: "2-digit",
						minute: "2-digit",
					})}`}
				</MenuBarLanguageSpacer>
			</MenuBarLanguageContent>
		</MenuWrapper>
	);
};
