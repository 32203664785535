import React, { useState, useEffect } from "react";
import { useTranslate } from "../hooks/use-translate";
import { useLanguage } from "../hooks/use-language";
import Page from "../components/page";
import getImage from "../helpers/get-image";
import styled from "styled-components";
import Grid from "styled-components-grid";
import { useMedia } from "../hooks/use-media";
import Qrcode from "../components/qrcode";
import { gameLink } from "../helpers/game-link";
import socket from "../ws";
import { withRouter } from "react-router-dom";
import generateRandom from "../helpers/generate-random";

const Content = styled(Grid)`
	width: 100%;
	height: 100%;
`;

const DisturbColumn = styled(Grid.Unit)`
	display: flex;
	flex-direction: column;
	height: 100%;
`;

const CodeWrapper = styled.div`
	height: 200px;
	width: 100%;
`;

const Code = styled.div`
	margin: 1em;
	width: 400px;
	height: 120px;
	background: ${({ theme }) => theme.color.white};
	padding: 0.5em;
	box-sizing: border-box;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: flex-start;
	box-shadow: ${({ theme }) => theme.shadow.base};
`;

const CodeValue = styled.div`
	margin: ${({ dir }) => (dir === "LTR" ? `0 0 0 0.125em` : `0 0.125em 0 0`)};
	flex: 1;
	text-align: center;
	font-size: 4em;
`;

const DisturbWrapper = styled.div`
	flex: 1;
	width: 100%;
	flex-direction: column;
	justify-content: flex-end;
	display: flex;
`;

const DisturbCircle = styled.div`
	background: ${({ theme }) => theme.color.green};
	width: 400px;
	height: 400px;
	border-radius: 100%;
	padding: 8%;
	box-sizing: border-box;
	display: flex;
	flex-direction: column;
	align-items: center;
	justify-content: center;
	margin: 1em;
	box-shadow: ${({ theme }) => theme.shadow.base};
`;

const DisturbCircleContent = styled.div`
	color: ${({ theme }) => theme.color.white};
	text-align: center;
	width: 100%;
	& > a {
		text-decoration: underline;
	}
`;

const DisturbCircleImage = styled.div`
	background-image: ${({ image }) => (image ? `url(${image})` : null)};
	background-size: contain;
	background-position: center center;
	background-repeat: no-repeat;
	height: 3em;
	min-height: 3em;
	width: 6em;
	display: inline-block;
	margin-top: 1em;
`;

const ContentColumn = styled(Grid.Unit)`
	display: flex;
	flex-direction: column;
`;

const ClientList = styled.div`
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	margin: 0.75em;
	justify-content: flex-end;
`;

const ClientItemWrapper = styled.div`
	background-color: ${({ theme }) => theme.color.white};
	display: flex;
	flex-direction: row;
	padding: 0.5em;
	margin: 0.25em;
	box-shadow: ${({ theme }) => theme.shadow.base};
`;

const ClientItemIcon = styled.div`
	height: 1.5em;
	width: 1.5em;
	border-radius: 100%;
	border: 2px solid ${({ theme }) => theme.color.grayLight};
	background-image: ${({ src }) => (src ? `url(${src})` : null)};
	background-position: center center;
	background-size: contain;
	background-repeat: no-repeat;
`;

const ClientItemName = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: ${({ dir, open }) =>
		open ? (dir === "LTR" ? `0 0 0 0.5em` : `0 0.5em 0 0`) : null};
`;

const ClientItem = ({ client }) => {
	const { dir } = useLanguage();
	return (
		<ClientItemWrapper>
			<ClientItemIcon src={require("../static/icons/avatar.png")} />
			<ClientItemName dir={dir} open={client.name ? true : false}>
				{client.name}
			</ClientItemName>
		</ClientItemWrapper>
	);
};

const InitScreen = ({ eventId, gameId, code, game, clients }) => {
	const { invert } = useMedia();
	const { dir } = useLanguage();
	return (
		<Content>
			<DisturbColumn size={3 / 5}>
				<CodeWrapper>
					{code && (
						<Code>
							<Qrcode url={gameLink(eventId, gameId, code)} />
							<CodeValue dir={dir}>{code}</CodeValue>
						</Code>
					)}
				</CodeWrapper>
				<DisturbWrapper>
					<DisturbCircle>
						<DisturbCircleContent
							dangerouslySetInnerHTML={{
								__html: `${useTranslate("scanQr")}<br/><br/>${useTranslate(
									"questionsToHealth"
								)} ${game && game.topic && game.topic.title}`,
							}}
						/>
						<DisturbCircleImage image={invert} />
					</DisturbCircle>
				</DisturbWrapper>
			</DisturbColumn>
			<ContentColumn size={2 / 5}>
				<ClientList>
					{clients.map((client, index) => (
						<ClientItem key={index} client={client} />
					))}
				</ClientList>
			</ContentColumn>
		</Content>
	);
};

const InitScreenWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	flex: 1;
	justify-content: center;
	align-items: center;
`;

const InitScreenForm = styled.div`
	background: ${({ theme }) => theme.color.white};
	width: 100%;
	max-width: 350px;
	box-shadow: ${({ theme }) => theme.shadow.base};
	display: flex;
	flex-direction: column;
`;

const Message = styled.div`
	flex: 1;
	outline: none;
	margin: 0;
	height: 3em;
	font-size: ${({ theme }) => theme.fontSize.regular};
	padding: ${({ theme }) => theme.spacing.regular};
	justify-content: center;
	box-sizing: border-box;
	background: ${({ theme }) => theme.color.white};
	color: ${({ theme }) => theme.color.black};
	border: none;
	width: 100%;
	max-width: 100%;
	display: flex;
`;

const ChooseTopicScreen = () => {
	return (
		<InitScreenWrapper>
			<InitScreenForm>
				<Message>{useTranslate("chooseTopicOnMobileDevice")}</Message>
			</InitScreenForm>
		</InitScreenWrapper>
	);
};

const QuestionScreenWrapper = styled.div`
	display: flex;
	flex: 1;
	width: 100%;
	height: 100%;
	flex-direction: column;
`;

const QuestionScreenHeader = styled.div`
	width: 100%;
	height: 80px;
	padding: 0 ${({ theme }) => theme.spacing.large};
	box-sizing: border-box;
	display: flex;
	flex-direction: row;
	background-color: ${({ theme }) => theme.color.green};
	color: ${({ theme }) => theme.color.white};
	justify-content: center;
`;

const QuestionScreenHeaderColumn = styled.div`
	padding: 0 ${({ theme }) => theme.spacing.regular};
	font-size: ${({ theme }) => theme.fontSize.large};
	flex: 1;
	flex-direction: row;
	align-items: center;
	display: flex;
	justify-content: ${({ right }) => (right === "true" ? `flex-end` : null)};
`;

const QuestionScreenContent = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	padding: ${({ theme }) => theme.spacing.large};
`;

const QuestionScreenHeaderCountWrapper = styled.div`
	display: flex;
	flex-direction: row;
	align-items: center;
	margin: 0 0.25em;
	align-items: baseline;
`;

const QuestionScreenHeaderCountCurrent = styled.div`
	margin: 0 0.25em;
`;

const QuestionScreenHeaderCountMax = styled.div`
	margin: 0 0.25em;
`;

const formatNumber = (x, local) => {
	return x.toLocaleString(local);
};

const QuestionScreenHeaderCount = ({ current, max }) => {
	const { short } = useLanguage();
	let local = null;
	if (short === "cn") {
		local = "zh-Hans-CN-u-nu-hanidec";
	} else if (short === "ar") {
		local = "ar-EG";
	} else {
		local = short;
	}
	return (
		<QuestionScreenHeaderCountWrapper>
			<QuestionScreenHeaderCountCurrent>
				{formatNumber(current, local)}
			</QuestionScreenHeaderCountCurrent>
			/
			<QuestionScreenHeaderCountMax>
				{formatNumber(max, local)}
			</QuestionScreenHeaderCountMax>
		</QuestionScreenHeaderCountWrapper>
	);
};

const QuestionScreenContentBackground = styled.div`
	background-color: ${({ theme }) => theme.color.white};
	width: 100%;
	max-width: 1200px;
	max-height: 750px;
	margin: auto;
	box-shadow: ${({ theme }) => theme.shadow.base};
	display: flex;
	flex-direction: column;
`;

const QuestionScreenContentQuestion = styled.div`
	padding: ${({ theme }) => theme.spacing.large};
	font-size: 25px;
	box-shadow: ${({ theme }) => theme.shadow.base};
`;

const QuestionScreenContentAnswerWrapper = styled.div`
	flex: 1;
	display: grid;
	grid-template-columns: 50% 50%;
	padding: 0.25em;
`;

const QuestionScreenContentExplanation = styled.div`
	padding: ${({ theme, open }) =>
		open ? theme.spacing.large : `0 ${theme.spacing.large}`};
	max-height: ${({ open }) => (open ? `8em` : `0`)};
	transition: all 0.45s ease-in-out;
	overflow: hidden;
`;

const QuestionScreenContentAnswerItem = styled.div`
	padding: ${({ theme }) => `${theme.spacing.regular} ${theme.spacing.large}`};
	display: flex;
	flex-direction: column;
	margin: 0.25em;
	background: ${({ theme, open, correct }) =>
		open ? (correct ? theme.color.green : theme.color.red) : `#d3d3d357`};
	transition: all 0.3s ease-in-out;
`;

const QuestionScreenContentAnswerLetter = styled.div`
	margin: ${({ dir }) => (dir === "LTR" ? `0 0.5em 0 0` : `0 0 0 0.5em`)};
	font-weight: bold;
	color: ${({ theme, open }) => (open ? theme.color.white : null)};
	transition: all 0.3s ease-in-out;
`;

const QuestionScreenContentAnswerText = styled.div`
	color: ${({ theme, open }) => (open ? theme.color.white : null)};
	transition: all 0.3s ease-in-out;
`;

const QuestionScreenContentAnswerRowContent = styled.div`
	display: flex;
	flex-direction: row;
	flex: 1;
`;

const QuestionScreenContentAnswerRowUser = styled.div`
	max-height: ${({ open }) => (open ? `5em` : `0`)};
	overflow: hidden;
	flex-wrap: wrap;
	display: flex;
	flex-direction: row;
	margin: -0.25em;
	padding: 0.75em 0 0 0;
	transition: 0.3s max-height ease-in-out;
`;

const ClientItemAnswerWrapper = styled.div`
	background-color: ${({ theme }) => theme.color.white};
	display: flex;
	flex-direction: row;
	padding: 0.35em;
	margin: 0.25em;
`;

const ClientItemAnswerIcon = styled.div`
	height: 1em;
	width: 1em;
	border-radius: 100%;
	border: 2px solid ${({ theme }) => theme.color.grayLight};
	background-image: ${({ src }) => (src ? `url(${src})` : null)};
	background-position: center center;
	background-size: contain;
	background-repeat: no-repeat;
`;

const ClientItemAnswerName = styled.div`
	display: flex;
	flex-direction: column;
	justify-content: center;
	align-items: center;
	margin: ${({ dir }) => (dir === "LTR" ? `0 0 0 0.5em` : `0 0.5em 0 0`)};
	font-size: 0.8em;
`;

const ClientItemAnswer = ({ client }) => {
	const { dir } = useLanguage();
	return (
		<ClientItemAnswerWrapper>
			<ClientItemAnswerIcon src={require("../static/icons/avatar.png")} />
			<ClientItemAnswerName dir={dir}>{client.name}</ClientItemAnswerName>
		</ClientItemAnswerWrapper>
	);
};

const QuestionScreen = ({ game, topic, question, answers, showAnswer }) => {
	const { dir } = useLanguage();
	return (
		<QuestionScreenWrapper>
			<QuestionScreenHeader>
				<QuestionScreenHeaderColumn>
					{useTranslate("question")}
					<QuestionScreenHeaderCount
						current={question + 1}
						max={game.topic.questiongroups[topic].questions.length}
					/>
				</QuestionScreenHeaderColumn>
				<QuestionScreenHeaderColumn right={"true"}>
					{game.topic.questiongroups[topic].title}
				</QuestionScreenHeaderColumn>
			</QuestionScreenHeader>
			<QuestionScreenContent>
				<QuestionScreenContentBackground>
					<QuestionScreenContentQuestion>
						{game.topic.questiongroups[topic].questions[question].question}
					</QuestionScreenContentQuestion>
					<QuestionScreenContentAnswerWrapper>
						{game.topic.questiongroups[topic].questions[question].answer_1 !==
							"" && (
							<QuestionScreenContentAnswerItem
								open={showAnswer}
								correct={
									game.topic.questiongroups[topic].questions[question]
										.answer_1_bool
								}
							>
								<QuestionScreenContentAnswerRowContent>
									<QuestionScreenContentAnswerLetter
										dir={dir}
										open={showAnswer}
									>
										{useTranslate("answera")}
									</QuestionScreenContentAnswerLetter>
									<QuestionScreenContentAnswerText open={showAnswer}>
										{
											game.topic.questiongroups[topic].questions[question]
												.answer_1
										}
									</QuestionScreenContentAnswerText>
								</QuestionScreenContentAnswerRowContent>
								<QuestionScreenContentAnswerRowUser open={showAnswer}>
									{answers[question] &&
										answers[question].answers
											.filter(a => a.answer === 1)
											.map(({ name }, index) => (
												<ClientItemAnswer key={index} client={{ name }} />
											))}
								</QuestionScreenContentAnswerRowUser>
							</QuestionScreenContentAnswerItem>
						)}
						{game.topic.questiongroups[topic].questions[question].answer_2 !==
							"" && (
							<QuestionScreenContentAnswerItem
								open={showAnswer}
								correct={
									game.topic.questiongroups[topic].questions[question]
										.answer_2_bool
								}
							>
								<QuestionScreenContentAnswerRowContent>
									<QuestionScreenContentAnswerLetter
										dir={dir}
										open={showAnswer}
									>
										{useTranslate("answerb")}
									</QuestionScreenContentAnswerLetter>
									<QuestionScreenContentAnswerText open={showAnswer}>
										{
											game.topic.questiongroups[topic].questions[question]
												.answer_2
										}
									</QuestionScreenContentAnswerText>
								</QuestionScreenContentAnswerRowContent>
								<QuestionScreenContentAnswerRowUser open={showAnswer}>
									{answers[question] &&
										answers[question].answers
											.filter(a => a.answer === 2)
											.map(({ name }, index) => (
												<ClientItemAnswer key={index} client={{ name }} />
											))}
								</QuestionScreenContentAnswerRowUser>
							</QuestionScreenContentAnswerItem>
						)}
						{game.topic.questiongroups[topic].questions[question].answer_3 !==
							"" && (
							<QuestionScreenContentAnswerItem
								open={showAnswer}
								correct={
									game.topic.questiongroups[topic].questions[question]
										.answer_3_bool
								}
							>
								<QuestionScreenContentAnswerRowContent>
									<QuestionScreenContentAnswerLetter
										dir={dir}
										open={showAnswer}
									>
										{useTranslate("answerc")}
									</QuestionScreenContentAnswerLetter>
									<QuestionScreenContentAnswerText open={showAnswer}>
										{
											game.topic.questiongroups[topic].questions[question]
												.answer_3
										}
									</QuestionScreenContentAnswerText>
								</QuestionScreenContentAnswerRowContent>
								<QuestionScreenContentAnswerRowUser open={showAnswer}>
									{answers[question] &&
										answers[question].answers
											.filter(a => a.answer === 3)
											.map(({ name }, index) => (
												<ClientItemAnswer key={index} client={{ name }} />
											))}
								</QuestionScreenContentAnswerRowUser>
							</QuestionScreenContentAnswerItem>
						)}
						{game.topic.questiongroups[topic].questions[question].answer_4 !==
							"" && (
							<QuestionScreenContentAnswerItem
								open={showAnswer}
								correct={
									game.topic.questiongroups[topic].questions[question]
										.answer_4_bool
								}
							>
								<QuestionScreenContentAnswerRowContent>
									<QuestionScreenContentAnswerLetter
										dir={dir}
										open={showAnswer}
									>
										{useTranslate("answerd")}
									</QuestionScreenContentAnswerLetter>
									<QuestionScreenContentAnswerText open={showAnswer}>
										{
											game.topic.questiongroups[topic].questions[question]
												.answer_4
										}
									</QuestionScreenContentAnswerText>
								</QuestionScreenContentAnswerRowContent>
								<QuestionScreenContentAnswerRowUser open={showAnswer}>
									{answers[question] &&
										answers[question].answers
											.filter(a => a.answer === 4)
											.map(({ name }, index) => (
												<ClientItemAnswer key={index} client={{ name }} />
											))}
								</QuestionScreenContentAnswerRowUser>
							</QuestionScreenContentAnswerItem>
						)}
					</QuestionScreenContentAnswerWrapper>

					<QuestionScreenContentExplanation open={showAnswer}>
						{game.topic.questiongroups[topic].questions[question].explanation}
					</QuestionScreenContentExplanation>
				</QuestionScreenContentBackground>
			</QuestionScreenContent>
		</QuestionScreenWrapper>
	);
};

const ResultScreenWrapper = styled.div`
	display: flex;
	flex-direction: column;
	width: 100%;
	height: 100%;
	flex: 1;
	justify-content: center;
	align-items: center;
`;

const ResultScreenList = styled.div`
	background: ${({ theme }) => theme.color.white};
	width: 100%;
	max-width: 350px;
	box-shadow: ${({ theme }) => theme.shadow.base};
	display: flex;
	flex-direction: column;
`;

const ResultScreenListItem = styled.div`
	display: flex;
	flex-direction: row;
	padding: 0.5em;
	align-items: center;
	justify-content: center;
`;

const ResultScreenListItemName = styled.div`
	display: flex;
	flex: 1;
`;

const ResultScreenListItemChart = styled.div`
	display: flex;
	flex-direction: row;
	width: 100px;
	height: 1.25em;
`;

const ResultScreenListItemChartCorrect = styled.div`
	display: flex;
	flex: ${({ flex }) => flex};
	height: 100%;
	background: ${({ theme }) => theme.color.green};
`;

const ResultScreenListItemChartWrong = styled.div`
	display: flex;
	flex: ${({ flex }) => flex};
	height: 100%;
	background: ${({ theme }) => theme.color.red};
`;

const getCorrectAnswerAmount = (id, answers) => {
	let count = 0;
	answers.forEach(({ answers }) => {
		count += answers.filter(a => a.correct === true && a.id === id).length;
	});
	return count;
};

const getWrongAnswerAmount = (id, answers) => {
	let count = 0;
	answers.forEach(({ answers }) => {
		count += answers.filter(a => a.correct === false && a.id === id).length;
	});
	return count;
};

const ResultScreen = ({ game, topic, clients, answers }) => {
	const tempClients = clients.map(client => ({
		...client,
		correct: getCorrectAnswerAmount(client.id, answers),
		wrong: getWrongAnswerAmount(client.id, answers),
	}));
	return (
		<ResultScreenWrapper>
			<ResultScreenList>
				{tempClients
					.sort((a, b) => b.correct - a.correct)
					.map(({ id, name, correct, wrong }, index) => (
						<ResultScreenListItem key={index}>
							<ResultScreenListItemName>{name}</ResultScreenListItemName>
							<ResultScreenListItemChart>
								<ResultScreenListItemChartCorrect flex={correct} />
								<ResultScreenListItemChartWrong flex={wrong} />
							</ResultScreenListItemChart>
						</ResultScreenListItem>
					))}
			</ResultScreenList>
		</ResultScreenWrapper>
	);
};

export default withRouter(
	({
		history,
		match: {
			params: { gameId, eventId, persist },
		},
		location: {
			pathname	
		},
		data: { loading, game, error },
	}) => {
		const [screen, setScreen] = useState("init");
		const [code, setCode] = useState(null);
		const [clients, setClients] = useState([]);
		const { short } = useLanguage();
		const [monitor] = useState({
			gameId,
			eventId,
			random: generateRandom(),
			short,
		});
		const [topics, setTopics] = useState([]);
		const [topic, setTopic] = useState(null);
		const [question, setQuestion] = useState(null);
		const [showAnswer, setShowAnswer] = useState(false);
		const [answers, setAnswers] = useState([]);

		const getTopic = localTopics => {
			if (game) {
				let count = {};
				localTopics.forEach(function(i) {
					count[i.topic] = (count[i.topic] || 0) + 1;
				});
				let mostChoosen = Object.keys(count).reduce((a, b) =>
					count[a] > count[b] ? a : b
				);
				return game.topic.questiongroups.findIndex(g => g._id === mostChoosen);
			} else {
				return null;
			}
		};

		const getQuestion = (topic, localQuestion) => {
			if (game) {
				const questions = game.topic.questiongroups[topic].questions;
				if (localQuestion < questions.length - 1) {
					return localQuestion + 1;
				} else {
					return -1;
				}
			} else {
				return null;
			}
		};

		const initSocket = () => {
			let localClients = [];
			let localTopics = [];
			let localQuestion = -1;
			let localAnswers = [];
			socket.emit("hostAdd", JSON.stringify(monitor));
			socket.on("hostCode", msg => setCode(JSON.parse(msg).code));
			socket.on("clients", msg => {
				const arr = JSON.parse(msg);
				if (arr.length === 0) {
					if (persist && persist === "1") {
						document.location.reload(true);
					} else {
						document.location.assign(
              `${window.location.protocol}//${
                window.location.hostname
              }/${game.language.short}/host/${eventId}`
            );
					}
				} else {
					localClients = arr.filter(({ setup }) => setup === true);
					setClients(arr.filter(({ setup }) => setup === true));
				}
			});
			socket.on("clientAction", msg => {
				const res = JSON.parse(msg);
				if (res.topic) {
					localTopics = [...localTopics, { client: res.id, topic: res.topic }];
					setTopics([...topics, { client: res.id, topic: res.topic }]);
					if (localTopics.length !== localClients.length) {
						socket.emit(
							"hostAction",
							JSON.stringify({ to: res.id, screen: "waitfortopics" })
						);
					} else {
						const topic = getTopic(localTopics);
						const question = getQuestion(topic, localQuestion);
						localQuestion = question;
						if (topic !== null && question !== null) {
							setTopic(topic);
							setQuestion(question);
							socket.emit(
								"hostAction",
								JSON.stringify({
									screen: "question",
									questionState: { topic, question },
								})
							);
							setScreen("question");
						}
					}
				}
				if (res.answer) {
					if (
						localAnswers.findIndex(l => l.question === localQuestion) !== -1
					) {
						localAnswers[
							localAnswers.findIndex(l => l.question === localQuestion)
						].answers.push({
							...res.answer,
							id: res.id,
							name:
								localClients.filter(c => c.id === res.id).length === 1
									? localClients.filter(c => c.id === res.id)[0].name
									: null,
							correct:
								game.topic.questiongroups[res.answer.topic].questions[
									res.answer.question
								][`answer_${res.answer.answer}_bool`],
						});
					} else {
						const arr = [
							{
								...res.answer,
								id: res.id,
								name:
									localClients.filter(c => c.id === res.id).length === 1
										? localClients.filter(c => c.id === res.id)[0].name
										: null,
								correct:
									game.topic.questiongroups[res.answer.topic].questions[
										res.answer.question
									][`answer_${res.answer.answer}_bool`],
							},
						];
						localAnswers = [
							...localAnswers,
							{ question: localQuestion, answers: arr },
						];
					}
					setAnswers(localAnswers);
					if (
						localAnswers[
							localAnswers.findIndex(l => l.question === localQuestion)
						].answers.length === localClients.length
					) {
						if (
							localQuestion <
							game.topic.questiongroups[getTopic(localTopics)].questions
								.length -
								1
						) {
							socket.emit(
								"hostAction",
								JSON.stringify({
									screen: "answer",
								})
							);
						} else {
							socket.emit(
								"hostAction",
								JSON.stringify({
									screen: "showresult",
								})
							);
						}

						setShowAnswer(true);
					} else {
						socket.emit(
							"hostAction",
							JSON.stringify({
								to: res.id,
								screen: "waitforanswers",
							})
						);
					}
				}
				if (res.nextquestion) {
					setShowAnswer(false);
					const topic = getTopic(localTopics);
					const question = getQuestion(topic, localQuestion);
					localQuestion = question;
					if (topic !== null && question !== null) {
						if (question !== -1) {
							setQuestion(question);
							socket.emit(
								"hostAction",
								JSON.stringify({
									screen: "question",
									questionState: { topic, question },
								})
							);
							setScreen("question");
						}
					}
				}
				if (res.showresult) {
					setScreen("result");
					socket.emit(
						"hostAction",
						JSON.stringify({
							screen: "result",
						})
					);
				}
			});
			socket.on("clientLockHost", () => {
				setScreen("choosetopic");
				socket.emit("hostAction", JSON.stringify({ screen: "choosetopic" }));
			});
		};
		useEffect(() => {
			socket.open();
			if (game) {
				initSocket();
			}
			return () => {
				socket.close();
			};
		}, [game]);
		return (
			<Page
				title={game && game.title}
				loading={loading}
				error={error}
				image={getImage(game)}
			>
				{screen === "init" && (
					<InitScreen
						eventId={eventId}
						gameId={gameId}
						code={code}
						game={game}
						clients={clients}
					/>
				)}
				{screen === "choosetopic" && <ChooseTopicScreen />}
				{screen === "question" && (
					<QuestionScreen
						game={game}
						topic={topic}
						question={question}
						answers={answers}
						showAnswer={showAnswer}
					/>
				)}
				{screen === "result" && (
					<ResultScreen
						game={game}
						topic={topic}
						clients={clients}
						answers={answers}
					/>
				)}
			</Page>
		);
	}
);
