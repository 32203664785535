import React from "react";
import styled from "styled-components";
import { useTranslate } from "../../hooks/use-translate";

const ErrorWrapper = styled.div`
	display: flex;
	flex-direction: column;
	flex: 1;
	height: 100%;
	width: 100%;
	justify-content: center;
	align-items: center;
`;

const ErrorText = styled.div``;

export default ({ error }) => (
	<ErrorWrapper>
		<ErrorText>{useTranslate("error")}</ErrorText>
	</ErrorWrapper>
);
