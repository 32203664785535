import React, { useState } from "react";
import { useTranslate } from "../hooks/use-translate";
import Page from "../components/page";
import { useMedia } from "../hooks/use-media";
import styled from "styled-components";
import Form from "../components/form";
import { Redirect } from "react-router-dom";
import { useRoute } from "../hooks/use-route";

const PageContentWrapper = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

const PageContent = styled.div`
	background: ${({ theme }) => theme.color.white};
	width: 100%;
	max-width: 350px;
	box-shadow: ${({ theme }) => theme.shadow.base};
`;

const PageFormErrorWrapper = styled.div`
	max-height: ${({ open }) => (open ? `10em` : `0`)};
	opacity: ${({ open }) => (open ? `1` : `0`)};
	transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
	box-sizing: border-box;
	overflow: hidden;
	background: ${({ theme }) => theme.color.redLight};
`;

const PageFormError = styled.div`
	margin: ${({ theme }) => theme.spacing.regular};
`;

export default ({ data: { loading, error, events } }) => {
	const { background } = useMedia();
	const [show, setShow] = useState(false);
	const [found, setFound] = useState([]);
	const checkEvent = (code, events) => {
		const found = events.filter(e => e.roomcode === code);
		if (found.length === 1) {
			setFound(found);
			setShow(false);
		} else {
			setFound([]);
			setShow(true);
		}
	};

	if (events && !show && found.length === 1) {
		return <Redirect to={`${useRoute("host")}/${found[0]._id}`} />;
	}
	return (
		<Page
			title={useTranslate("host")}
			loading={loading}
			error={error}
			image={background}
		>
			<PageContentWrapper>
				<PageContent>
					<PageFormErrorWrapper open={show && found.length === 0}>
						<PageFormError>{useTranslate("codeNotFound")}</PageFormError>
					</PageFormErrorWrapper>
					<Form
						getChange={() => {
							setShow(false);
						}}
						fields={[
							{
								placeholder: useTranslate("code"),
								type: "text",
								autocomplete: "code",
								name: "code",
								required: true,
							},
						]}
						submit={{
							title: useTranslate("start"),
							action: ({ code }) => checkEvent(code, events),
						}}
					/>
				</PageContent>
			</PageContentWrapper>
		</Page>
	);
};
