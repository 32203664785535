import LangRouter from "../containers/langRouter";
import { graphql } from "react-apollo";
import gql from "graphql-tag";
const Query = gql`
	query getLanguage($lng: String) {
		language: languages(where: { short: $lng }) {
			_id
			title
			short
			flag
			ltr
			default
			translation {
				_id
				text
				translationkey {
					_id
					key
				}
			}
		}
	}
`;
const QueryParams = {
	options: props => ({
		variables: { lng: props.match.params.lng },
	}),
};
export default graphql(Query, QueryParams)(LangRouter);
