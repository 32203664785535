import Device from "../containers/device";
import { graphql } from "react-apollo";
import gql from "graphql-tag";

const Query = gql`
	query getDevice($device: ID!) {
		device(id: $device) {
			_id
			title
			description
			route
		}
	}
`;

const QueryParams = {
	options: props => ({
		variables: { device: props.match.params.deviceId },
	}),
};

export default graphql(Query, QueryParams)(Device);
