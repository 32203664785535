export default {
	color: {
		white: "white",
		black: "black",
		green: "#008d44",
		red: "#e30038",
		redLight: "#f7796f",
		yellow: "#ffc900",
		blue: "#00a2e6",
		purple: "#870184",
		purpleLight: "#87018463",
		grayLight: "lightgray",
		lightgray: "lightgray",
		opacity: "rgba(0, 0, 0, 0.5)",
	},
	fontSize: {
		small: "15px",
		regular: "20px",
		large: "30px",
	},
	shadow: {
		base: "rgba(0, 0, 0, 0.2) 1px 0px 20px 1px",
	},
	breakpoints: { xs: 0, sm: 576, md: 768, lg: 992, xl: 1200 },
	spacing: {
		small: "8px",
		regular: "16px",
		large: "24px",
	},
};
