import React, { useState, useEffect } from "react";
import { useTranslate } from "../hooks/use-translate";
import Page from "../components/page";
import { useMedia } from "../hooks/use-media";
import styled from "styled-components";
import Form from "../components/form";
import socket from "../ws";
import { Redirect } from "react-router-dom";

const PageContentWrapper = styled.div`
	display: flex;
	width: 100%;
	height: 100%;
	flex-direction: column;
	align-items: center;
	justify-content: center;
`;

const PageContent = styled.div`
	background: ${({ theme }) => theme.color.white};
	width: 100%;
	max-width: 350px;
	box-shadow: ${({ theme }) => theme.shadow.base};
`;

const PageFormErrorWrapper = styled.div`
	max-height: ${({ open }) => (open ? `10em` : `0`)};
	opacity: ${({ open }) => (open ? `1` : `0`)};
	transition: max-height 0.3s ease-in-out, opacity 0.3s ease-in-out;
	box-sizing: border-box;
	overflow: hidden;
	background: ${({ theme }) => theme.color.redLight};
`;

const PageFormError = styled.div`
	margin: ${({ theme }) => theme.spacing.regular};
`;

export default () => {
	const { background } = useMedia();
	const [show, setShow] = useState(false);
	const [found, setFound] = useState({});
	const checkCode = code => {
		socket.emit("clientCode", JSON.stringify({ code }));
	};

	const initSocket = () => {
		socket.on("clientCode", msg => {
			const { host } = JSON.parse(msg);
			if (!host.code && !host.lock) {
				setShow(true);
				setFound({});
			} else {
				setShow(false);
				setFound(host);
			}
		});
	};
	useEffect(() => {
		socket.open();
		initSocket();
		return () => {
			socket.close();
		};
	}, []);

	if (!show && found.code) {
		return (
			<Redirect
				to={`/${found.short}/game/${found.eventId}/${found.gameId}/${
					found.code
				}`}
				push={true}
			/>
		);
	}

	return (
		<Page title={useTranslate("code")} image={background}>
			<PageContentWrapper>
				<PageContent>
					<PageFormErrorWrapper open={show && !found.code}>
						<PageFormError>{useTranslate("codeNotFound")}</PageFormError>
					</PageFormErrorWrapper>
					<Form
						getChange={() => {
							setShow(false);
						}}
						fields={[
							{
								placeholder: useTranslate("code"),
								type: "number",
								autocomplete: "code",
								name: "code",
								required: true,
							},
						]}
						submit={{
							title: useTranslate("start"),
							action: ({ code }) => checkCode(code),
						}}
					/>
				</PageContent>
			</PageContentWrapper>
		</Page>
	);
};
