import Navigation from "../containers/navigation";
import { graphql } from "react-apollo";
import gql from "graphql-tag";

const Query = gql`
	query init {
		languages {
			_id
			title
			short
			flag
			ltr
			default
		}
		medias: defaultmedias {
			_id
			logo {
				url
			}
			logo_invert {
				url
			}
			background {
				url
			}
		}
	}
`;

export default graphql(Query)(Navigation);
