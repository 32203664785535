import Host from "../containers/host";
import { graphql } from "react-apollo";
import gql from "graphql-tag";

const Query = gql`
	query getEvents($now: String, $lng: ID) {
		events(where: { start_lte: $now, end_gte: $now, language: $lng }) {
			_id
			title
			start
			end
			roomcode
		}
	}
`;

const QueryParams = {
	options: ({ lng }) => ({
		variables: { now: new Date(), lng },
	}),
};

export default graphql(Query, QueryParams)(Host);
