import React from "react";
import { ApolloProvider } from "react-apollo";
import { injectGlobal, ThemeProvider } from "styled-components";
import client from "../../client";
import theme from "../../constants/theme";
import Navigation from "../../screens/navigation";
import book from "../../static/fonts/lineto-circular-book.woff";

injectGlobal`
	@font-face {
		font-family: 'Lineto Circular Book';
		src: url(${book}) format('woff');
		font-weight: normal;
		font-style: normal;
	}
	body {
		margin: 0;
		padding: 0;
		font-family: 'Lineto Circular Book';
		font-size: ${theme.fontSize.regular};
		color: ${theme.color.black};
	}
	input, textarea {
		font-family: 'Lineto Circular Book';
		font-size: ${theme.fontSize.regular};
		color: ${theme.color.black};
		border-radius: 0;
		-webkit-appearance: none;
	}
	html, body, #root {
		height: 100%;
    	width: 100%;
	}
`;

export default () => {
	return (
		<ApolloProvider client={client}>
			<ThemeProvider theme={theme}>
				<Navigation />
			</ThemeProvider>
		</ApolloProvider>
	);
};
